
// Fuentes
$font-primary: 'Roboto', sans-serif;
$font-secondary: 'Helvetica', sans-serif;

// Colores
$color-primary: #FFFFFF;
$color-secondary: #000000;
$color-terciary: #080808;
$color-accent-primary: #B7BF28;
$color-accent-secondary: #B5C000;


// Tipografía
// Tipografía
$font-size-h1: 50px;
$font-weight-h1: 800;

$font-size-h2: 25px;
$font-weight-h2-light: 300;
$font-weight-h2-bold: 700;

$font-size-h3: 20px;
$font-weight-h3: 400;

$font-size-p: 15px;
$font-weight-p: 400;