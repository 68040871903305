

@mixin button-styles($background-color, $text-color, $border-color: transparent) {
    display: inline-block;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    text-decoration: none;
    border: 1px solid $border-color;
    color: $text-color;
    background-color: $background-color;
    cursor: pointer;
    border-radius: 15px;
    padding: 5px;
    width: 222px;
    height: 54px
    }
  
  @mixin button-filled($background-color, $text-color) {
    @include button-styles($background-color, $text-color);
  }
  
  @mixin button-outlined($border-color, $text-color) {
    @include button-styles(transparent, $text-color, $border-color);
  }
  